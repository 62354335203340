import React, { useEffect, useRef } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Page = () => {
  const buttonRef = useRef(null);

  const handleMousemove = (event: MouseEvent) => {
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const buttonX = buttonRef.current.getBoundingClientRect().x;
    const buttonWidth = buttonRef.current.getBoundingClientRect().width;
    const buttonY = buttonRef.current.getBoundingClientRect().y;
    const buttonHeight = buttonRef.current.getBoundingClientRect().height;

    const buttonCenterX = buttonX + buttonWidth / 2;
    const buttonCenterY = buttonY + buttonHeight / 2;

    const percentageDown = (100 * (mouseY - buttonCenterY)) / buttonHeight;
    const percentageRight = (100 * (mouseX - buttonCenterX)) / buttonWidth;

    buttonRef.current.style.position = "absolute";

    if (percentageDown > 0 && percentageDown > Math.abs(percentageRight)) {
      console.log("UP");
      buttonRef.current.style.top = `${mouseY - buttonHeight - 1}px`;
    }
    if (
      percentageDown < 0 &&
      Math.abs(percentageDown) > Math.abs(percentageRight)
    ) {
      console.log("DOWN");
      buttonRef.current.style.top = `${mouseY + 2}px`;
    }

    if (percentageRight > 0 && percentageRight > Math.abs(percentageDown)) {
      console.log("LEFT");
      buttonRef.current.style.left = `${mouseX - buttonWidth - 1}px`;
    }
    if (
      percentageRight < 0 &&
      Math.abs(percentageRight) > Math.abs(percentageDown)
    ) {
      console.log("RIGHT");
      buttonRef.current.style.left = `${mouseX + 2}px`;
    }

    buttonRef.current.innerText = `Can you click me?`;
  };

  useEffect(() => {
    if (buttonRef.current != null) {
      buttonRef.current.addEventListener("mousemove", handleMousemove);

      return () => {
        buttonRef.current.removeEventListener("mousemove", handleMousemove);
      };
    }
    return () => {};
  }, [handleMousemove]);

  return (
    <Layout>
      <SEO title="Unclickable Button" />
      <h1>Click me!</h1>
      <button ref={buttonRef}>Can you click me?</button>
    </Layout>
  );
};

export default Page;
